import React from 'react'
import {Header} from "../Header";
import {Menu} from "../Menu";
// import {Job} from "./Job";
import {Jobssssssssssssss} from "./Jobssssssssssssss"

export function JobDetails() {
    return (
        <div>
            <Header />
            <div className='d-flex'>
            <Menu />
            {/* <Job /> */}
            <Jobssssssssssssss />
            </div>
           
           
        </div>
    )
}

import React from "react";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
      <div>
        <p>Please wait, the page is loading...</p>
      </div>
     
    </div>
  );
}